<template>
    <div class="background-page new-form-page">
        <div class="form-wizard-container">
            <div class="sidebar" v-if="loadedSofts && loaded">
                <div class="menu-wizard">
                    <template v-for="step in steps">
                        <div class="step-item" 
                        :class="{active: activeStep == step.no, disabled: (typeCrud == 'create') && step.no !== 1}" 
                        @click="changeStep(step.no)" 
                        v-if="step.visible">
                            <div class="circle"></div>
                            <div class="text">{{ $t(`create-user.steps.${step.name}.menu`) }}</div>
                        </div>
                    </template>
                </div>

                <div class="footer-info">
                    <div class="vacant-post-box" v-if="typeCrud == 'create'">
                        <div class="title">
                            {{ $tc('vacant_jobs.alert_create_user_title', optionsVacantJobs.length) }}
                        </div>
                        <div class="description">
                            {{ $t('vacant_jobs.alert_create_user_description', { items: optionsVacantJobs.map(el => { return el.name }).join(", ") }) }}
                        </div>
                        <div class="input-box">
                            <div class="icon-left"><icon-vacant-job /></div>
                            <multiselect 
                            v-model="selectedVacantJob"
                            :options="optionsVacantJobs"
                            :allow-empty="true"
                            :show-labels="false"
                            :multiple="false" 
                            :close-on-select="true"
                            @input="populateDataByVacantJob"
                            >
                                <template slot="placeholder" slot-scope="props">
                                    <span class="text">{{$t('vacant_jobs.choose_option')}}</span>
                                </template>
                                <template slot="option" slot-scope="props">{{ props.option.name }}</template>
                                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                <template slot="noResult">{{ $t('general.no_result') }}</template>
                                <template slot="noOptions">{{ $t('general.empty_list') }}</template>
                            </multiselect>
                        </div>
                    </div>
                    <button class="btn-tbf white center btn-finish" id="buttonFinishedFrom" @click="finishForm()">
                        <div class="loader-spin" v-if="loadedFinish"></div>
                        <div class="text">{{ $t('general.finish') }}</div>
                    </button>

                    <a class="link-footer" :href="helpData.link" target="_blank" v-if="helpData">
                        {{ $t(`help_links.${helpData.type}`) }}
                    </a>
                </div>
            </div>
            <div class="content" v-if="loadedSofts && loaded">
                <div class="header">
                    <div class="title">{{ typeCrud == 'create' ? $t('create-user.create-user') : $t(`create-user.steps.${steps.find(el => el.no == activeStep).name}.title`) }}</div>
                    
                    <div class="actions">
                        <button class="btn-tbf white only-icon" @click="$router.push({name: 'users'})"><icon-close class="icon-close" /></button>
                    </div>
                </div>
                <div class="body">
                    <required-data ref="stepRequiredData" :selectedVacantJob="selectedVacantJob" :typeCrud="typeCrud" :user="userData" @nextStep="changeStepArrows('next')" @goToStep="changeActiveComponent" v-if="activeStep == 1" />
                    <personal-details ref="stepPersonalDetails" :user="userData" @nextStep="changeStepArrows('next')" @prevStep="changeStepArrows('prev')" @goToStep="changeActiveComponent" v-if="activeStep == 2" />
                    <job-details ref="stepJobDetails" :user="userData" @nextStep="changeStepArrows('next')" @prevStep="changeStepArrows('prev')" @goToStep="changeActiveComponent" v-if="activeStep == 3" />
                    <rights ref="stepRights" :user="userData" @nextStep="changeStepArrows('next')" @prevStep="changeStepArrows('prev')" @goToStep="changeActiveComponent" v-if="activeStep == 4" />
                    <notifications ref="stepNotifications" :user="userData" @nextStep="changeStepArrows('next')" @prevStep="changeStepArrows('prev')" @goToStep="changeActiveComponent" v-if="activeStep == 5" />
                    <documents ref="stepDocuments" :user="userData" @prevStep="changeStepArrows('prev')" @goToStep="changeActiveComponent" v-if="activeStep == 6" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconClose from '@/components/Icons/Close'
import IconQuestion from '@/components/Icons/Question'
import IconVacantJob from '@/components/Icons/VacantJob'

import RequiredData from './RequiredData'
import PersonalDetails from './PersonalDetails'
import JobDetails from './JobDetails'
import Rights from './Rights'
import Notifications from './Notifications'
import Documents from './Documents'

export default {
    components: {
        IconClose,
        IconQuestion,
        IconVacantJob,
        RequiredData,
        PersonalDetails,
        JobDetails,
        Rights,
        Notifications,
        Documents
    },
    computed: {
        soft_procedures() {
            return this.$store.getters['applications/getApplication']('Proceduri');
        },
        soft_objectives() {
            return this.$store.getters['applications/getApplication']('Obiective');
        },
        soft_organigram() {
            return this.$store.getters['applications/getApplication']('Organigrama');
        },
        loadedSofts() {
            return this.$store.state.applications.applications.length;
        },
        steps() {
            return [ 
                { name: 'stepRequiredData', no: 1, visible: true },
                { name: 'stepPersonalDetails', no: 2, visible: this.soft_organigram.is_active },
                { name: 'stepJobDetails', no: 3, visible: true },
                { name: 'stepRights', no: 4, visible: true },
                { name: 'stepNotifications', no: 5, visible: this.soft_procedures.is_active || this.soft_objectives.is_active },
                { name: 'stepDocuments', no: 6, visible: this.soft_organigram.is_active }
            ]
        }
    },
    data() {
        return {
            loaded: false,
            loadedFinish: false,
            typeCrud: 'create',
            userData: {},
            activeStep: 1,
            helpData: this.$store.getters['help_links/currentLink']('user-create'),
            optionsVacantJobs: [],
            selectedVacantJob: '',
        }
    },
    async mounted() {
        await this.getFilters();

        if(this.$route.params.slug){
            this.typeCrud = 'edit';
            this.getUserData()
        } else if(this.$route.query.vacant_job && this.optionsVacantJobs.find(el => el.slug == this.$route.query.vacant_job)){
            this.selectedVacantJob = this.optionsVacantJobs.find(el => el.slug == this.$route.query.vacant_job)
            this.populateDataByVacantJob(this.selectedVacantJob)
        } else {
            this.loaded = true;
        }
    },
    methods: {
        getUserData() {
            axios.get(`/users/${this.$route.params.slug}/edit`)
            .then(({data}) => {
                this.userData = data.data;
            })
            .finally(() => {
                this.loaded = true;
            })
        },
        changeStep(stepNo) {
            if(this.typeCrud == 'create') {
                return true;
            } else {
                var refComponentActive = this.steps.find(el => el.no == this.activeStep).name;
                
                this.$refs[refComponentActive].changeStepFunction(false, stepNo);
            }
        },
        changeActiveComponent(stepNo) {
            this.activeStep = stepNo;
        },
        finishForm() {
            var refComponentActive = this.steps.find(el => el.no == this.activeStep).name;
            // Call the saveAction form component 
            // First parameter changeStep = false
            // Second parameter withRedirect = true
            this.$refs[refComponentActive].saveAction(false, true);
        },
        changeStepArrows( type ) {
            var stepFound = '';
            if ( type == 'next' ) {
                stepFound = this.steps.find(el => el.no > this.activeStep && el.visible);
            } else {
                var newArray = JSON.stringify(this.steps);
                stepFound = JSON.parse(newArray).sort((a, b) => a.no < b.no ? 1 : -1).find(el => el.no < this.activeStep && el.visible);
            }

            this.activeStep = stepFound ? stepFound.no : this.activeStep;
        },
        async getFilters(){
            var filtersDB = {
                vacant_jobs: true
            }

            await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: filtersDB })
            .then(({data}) => {
                this.optionsVacantJobs = data.data.vacant_jobs
            }).catch(error => {
                if(error.response) {
                    if(error.response.status == 500) {
                        alert(this.$t('error.500'))
                    }
                }
            })
            .finally(() => {
                if(!this.$route.params.slug){
                    setTimeout(() => {
                        this.loaded = true
                        setTimeout(() => {
                            $('.opacity-page').addClass('show')
                        }, 0)
                    }, 0)
                }
            })
        },
        populateDataByVacantJob(value, id){
            axios.get(`/vacant-jobs/${value.slug}/show`)
            .then(({data}) => {

                if(data.data.work_schedule){
                    var work_schedulesParse = {
                        name: data.data.work_schedule.name,
                        schedules: data.data.work_schedule.schedules.map(el => { return {
                            day: el.day,
                            end_time: el.end_time,
                            start_time: el.start_time
                        }})
                    }
                }

                var vacantJobData = {
                    workstation_id: data.data.workstation? data.data.workstation.id : '',
                    skills: data.data.skills.length ? data.data.skills.map((el) => { return {id: el.id, level: el.level, name: el.name}}) : '',
                    departments: data.data.departments.length ? data.data.departments.map((el) => { return el.id}) : '',
                    roles: data.data.roles.length ? data.data.roles.map((el) => { return el.id }) : '',
                    foreign_languages: data.data.foreign_languages.length ? data.data.foreign_languages : '',
                    work_schedules: data.data.work_schedule ? work_schedulesParse : ''

                }

                this.userData = vacantJobData
            })
            .finally(() => {
                this.loaded = true;
            })
        }
    }
}
</script>